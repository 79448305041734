import countdown from "./js/countdown";
import populateReasonsAndWebsites from "./js/reasonsAndWebsites";

countdown(
  "2022-06-15T12:52:56+00:00",
  "extendedTimer",
  "Support ends in",
  "Internet Explorer is finally dead and has been dead for"
);
populateReasonsAndWebsites();
